import { FC, useEffect, useState } from 'react'
import { Grid } from '../../../../../../../UI'
import useStyles from '../../../styles'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { useStepperContext } from '../../../../../../../../hooks/useStepperContext'
import ListingAgreement from '../../../components/ListingAgreement'
import { useDispatch, useSelector } from 'react-redux'
import { getApprovalStep, getEstimate } from 'ducks/selectors'
import BottomButtons from '../../../components/BottomButtons'
import { configActions, estimateActions } from 'ducks/actions'
import { FILE_TYPE } from 'helpers/constants'
import { PunchlistFile } from 'ducks/types'
import { UpdateRequest } from 'api/types'
import ComparableMarketAnalysis from '../../../components/ComparableMarketAnalysis'

const Step3Agent: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { setCanGoForward } = useStepperContext()
  const approvalStep = useSelector(getApprovalStep)
  const estimate = useSelector(getEstimate())
  // const { documents } = useSelector(getApprovalValues())
  const [listingAgreementDocument, setListingAgreementDocument] = useState<
    PunchlistFile[]
  >([])
  const [comparableMarketAnalysis, setComparableMarketAnalysis] = useState<
    PunchlistFile[]
  >([])
  console.log({ comparableMarketAnalysis })
  const validate = estimate?.properties?.propertyUnderContract
    ? !!listingAgreementDocument?.length
    : !!listingAgreementDocument?.length && !!comparableMarketAnalysis?.length
  const alreadyUpdatedListingAgreement = estimate?.properties?.files?.find(
    (file) => file.fileType === FILE_TYPE.LISTING_AGREEMENT
  )
  const alreadyUpdatedComparableMarketAnalysis =
    estimate?.properties?.files?.find(
      (file) => file.fileType === FILE_TYPE.COMPARABLE_MARKET_ANALYSIS
    )
  const fileIndex = estimate?.properties?.files?.findIndex(
    (file) => file.fileType === FILE_TYPE.LISTING_AGREEMENT
  )

  const comparableMarketAnalysisfileIndex = estimate?.properties?.files?.findIndex(
    (file) => file.fileType === FILE_TYPE.COMPARABLE_MARKET_ANALYSIS
  )

  useEffect(() => {
    setCanGoForward(validate)
    // we get the homeowner info from the estimate
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validate])

  useEffect(() => {
    if (alreadyUpdatedListingAgreement) {
      setListingAgreementDocument([alreadyUpdatedListingAgreement])
    }
    if (alreadyUpdatedComparableMarketAnalysis) {
      setComparableMarketAnalysis([alreadyUpdatedComparableMarketAnalysis])
    }
  }, [alreadyUpdatedListingAgreement, alreadyUpdatedComparableMarketAnalysis])

  const onSubmit = () => {
    dispatch(
      configActions.setConfigValue({
        type: 'overlayLoading',
        value: true,
      })
    )
    const valuesToSend: UpdateRequest = [
      {
        op: alreadyUpdatedListingAgreement ? 'replace' : 'add',
        path: `/properties/files/${
          alreadyUpdatedListingAgreement ? fileIndex : '-'
        }`,
        value: {
          fileType: FILE_TYPE.LISTING_AGREEMENT,
          fileUrl: listingAgreementDocument[0]?.fileUrl,
          fileName: listingAgreementDocument[0]?.name,
          name: listingAgreementDocument[0]?.name,
          description: 'Listing Agreement',
        },
      },
    ]
    if (!estimate?.properties?.propertyUnderContract) {
      valuesToSend.push({
        op: alreadyUpdatedComparableMarketAnalysis ? 'replace' : 'add',
        path: `/properties/files/${
          alreadyUpdatedComparableMarketAnalysis ? comparableMarketAnalysisfileIndex : '-'
        }`,
        value: {
          fileType: FILE_TYPE.COMPARABLE_MARKET_ANALYSIS,
          fileUrl: comparableMarketAnalysis[0]?.fileUrl,
          fileName: comparableMarketAnalysis[0]?.name,
          name: comparableMarketAnalysis[0]?.name,
          description: 'Comparable Market Analysis',
        },
      })
    }
    dispatch(
      estimateActions.updateEstimateByPath(valuesToSend, (succ) => {
        succ &&
          dispatch(
            estimateActions.updateEstimateProperties(
              {
                approvalStep: approvalStep + 1,
              },
              (succ) =>
                succ &&
                dispatch(
                  configActions.setConfigValue({
                    type: 'overlayLoading',
                    value: false,
                  })
                )
            )
          )
      })
    )
  }

  return (
    <Grid className={classes.container} spacing={2}>
      <ListingAgreement
        listingAgreementDocument={listingAgreementDocument}
        setListingAgreementDocument={setListingAgreementDocument}
      />
      {!estimate?.properties?.propertyUnderContract && (
        <ComparableMarketAnalysis
          comparableMarketAnalysis={comparableMarketAnalysis}
          setComparableMarketAnalysis={setComparableMarketAnalysis}
        />
      )}
      <BottomButtons
        buttonBack={{
          title: 'Back',
          action: () => {
            dispatch(
              configActions.setConfigValue({
                type: 'overlayLoading',
                value: true,
              })
            )
            dispatch(
              estimateActions.updateEstimateByPath(
                [
                  {
                    op: 'replace',
                    path: '/properties/approvalStep',
                    value: approvalStep - 1,
                  },
                ],
                (succ) =>
                  dispatch(
                    configActions.setConfigValue({
                      type: 'overlayLoading',
                      value: false,
                    })
                  )
              )
            )
          },
        }}
        buttonNext={{
          title: 'Sign Agent Acknowledgment',
          action: onSubmit,
          disable: !validate,
        }}
      />
    </Grid>
  )
}

export default Step3Agent

import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  history,
  APPROVAL_TYPE,
  ESTIMATE_PUBLIC_STATUS,
  CONTACT_ROLE,
  ESTIMATE_STATUS,
  companyRoles,
  homeownerRoles,
} from '../../../../../helpers'
import { DesktopPage, PrivateLoader } from '../../../../templates'
import {
  approvalActions,
  configActions,
  estimateActions,
} from '../../../../../ducks/actions'
import {
  getEstimate,
  getUserIsHomeowner,
  getApprovalValues,
  getUserPrimaryEmail,
  getContactInEstimate,
} from '../../../../../ducks/selectors'
import AgentStepper from './Steps/Agent'
import HomeownerStepper from './Steps/Homeowner'
import HomeownerSecondApprovalStepper from './Steps/Homeowner/HomeownerSecondApprovalStepper'
import { Estimate } from 'ducks/types'

const showApproveInter = (userEmail: string, estimateFromParams?: Estimate) => {
  const estimateToUse = estimateFromParams
  const estimateHasOneRequiredHomeowner =
    estimateToUse?.properties?.contacts?.find(
      (contact: any) =>
        contact.role === CONTACT_ROLE.HOMEOWNER &&
        contact.approvalType === APPROVAL_TYPE.REQUIRED
    )
  const userIsAgentInEstimate = estimateToUse?.properties?.contacts?.find(
    (contact: any) =>
      contact.email === userEmail && companyRoles.includes(contact.role)
  )
  const userIsHomeownerInEstimate = estimateToUse?.properties?.contacts?.find(
    (contact: any) =>
      contact.email === userEmail && homeownerRoles.includes(contact.role)
  )
  const userIsAgentHomeownerInEstimate =
    estimateToUse?.properties?.contacts?.find(
      (contact: any) =>
        contact.email === userEmail &&
        contact.role === CONTACT_ROLE.AGENT_HOMEOWNER
    )
  const userIsRequiredHomeownerInEstimate =
    estimateToUse?.properties?.contacts?.find(
      (contact: any) =>
        contact.email === userEmail &&
        homeownerRoles.includes(contact.role) &&
        contact.approvalType === APPROVAL_TYPE.REQUIRED
    )

  const disableButton = userIsAgentHomeownerInEstimate
    ? false
    : (estimateHasOneRequiredHomeowner &&
        !userIsRequiredHomeownerInEstimate &&
        !userIsAgentInEstimate) ||
      (!userIsAgentInEstimate && !userIsHomeownerInEstimate)

  return !disableButton
}

const ApproveViewComponent: FC = () => {
  const dispatch = useDispatch()
  const [pageLoading, setPageLoading] = useState(true)
  const push = history.usePush()
  const pushForward = history.usePushForward()
  const userEmail = useSelector(getUserPrimaryEmail)
  const estimate = useSelector(getEstimate())
  const { id: estimateId } = history.getParamValues()
  const { role } = useSelector(getApprovalValues())
  const contactScheduler = estimate?.properties?.contacts?.find(
    (c) => c?.isScheduler
  )
  const isHomewardCXSupport = [
    'cxsupport@test.com',
    'cxsupport@homeward.com',
  ].includes(userEmail)

  const estimateHomeowners =
    estimate?.properties?.contacts?.filter((c) =>
      [
        CONTACT_ROLE.HOMEOWNER,
        CONTACT_ROLE.HOME_BUYER,
        CONTACT_ROLE.HOME_SELLER,
      ].includes(c.role as CONTACT_ROLE)
    ) || []

  const requiredHomeowner = estimate?.properties?.contacts?.find(
    (c) =>
      [
        CONTACT_ROLE.HOMEOWNER,
        CONTACT_ROLE.HOME_BUYER,
        CONTACT_ROLE.HOME_SELLER,
      ].includes(c.role as CONTACT_ROLE) &&
      c.approvalType === APPROVAL_TYPE.REQUIRED
  )

  const estimateHomeowner = requiredHomeowner
    ? requiredHomeowner
    : estimate?.properties?.contacts?.find((c) =>
        [
          CONTACT_ROLE.HOMEOWNER,
          CONTACT_ROLE.HOME_BUYER,
          CONTACT_ROLE.HOME_SELLER,
        ].includes(c.role as CONTACT_ROLE)
      ) || []

  const estimateHasAnApproverAlready = estimate?.properties?.contacts?.find(
    (c) => c.approvalType === APPROVAL_TYPE.APPROVED
  )

  const secondApproval =
    estimate?.approvalStatus !== 'APPROVED' &&
    estimateHasAnApproverAlready /*  &&
    (contact?.approvalType === APPROVAL_TYPE.REQUIRED ||
      contact?.approvalType === APPROVAL_TYPE.NOT_NEEDED) */

  const isHomeowner = getUserIsHomeowner()

  const agentIsHomeowner =
    !isHomeowner &&
    role &&
    [
      CONTACT_ROLE.HOMEOWNER,
      CONTACT_ROLE.HOME_BUYER,
      CONTACT_ROLE.HOME_SELLER,
      CONTACT_ROLE.AGENT_HOMEOWNER,
    ].includes(role as CONTACT_ROLE)

  const contactInEstimate = useSelector(getContactInEstimate(userEmail))

  const isOverInstallmentThreshold =
    estimate?.properties?.totalValue &&
    estimate?.properties?.installmentThreshold &&
    estimate?.properties?.totalValue >=
      estimate?.properties?.installmentThreshold

  const fetchEstimate = useCallback(
    () => {
      setPageLoading(true)

      dispatch(
        estimateActions.fetchEstimate(estimateId, (succ: boolean, estimate) => {
          if (succ) {
            const showApproveFlowInside = showApproveInter(userEmail, estimate)
            if (
              (estimate?.properties?.totalValue &&
                estimate?.properties?.minimum &&
                estimate?.properties?.totalValue <
                  estimate?.properties?.minimum) ||
              (!showApproveFlowInside && !isHomewardCXSupport)
            ) {
              push(`p/estimates/${estimateId}`)
            }
            dispatch(approvalActions.cleanApproval())
            setPageLoading(false)
          } else {
            push('p/estimates')
          }
        })
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, estimateId, showApproveInter]
  )

  useEffect(() => {
    //dispatch(approvalActions.cleanApproval())
    dispatch(
      configActions.setConfigValue({
        type: 'overlayLoading',
        value: false,
      })
    )
    dispatch(
      approvalActions.setApprovalValues({
        attrs: {
          role: contactInEstimate?.role || undefined,
          preferredStartDate: estimate?.properties?.preferredStartDate,
          preferredCompletedOn: estimate?.properties?.preferredCompletedOn,
          propertyUnderContract:
            typeof estimate?.properties?.propertyUnderContract !== 'undefined'
              ? estimate?.properties?.propertyUnderContract
              : undefined,
          closingDate: estimate?.properties?.closingDate,
          completedBeforeClosing:
            typeof estimate?.properties?.completedBeforeClosing !== 'undefined'
              ? estimate?.properties?.completedBeforeClosing
              : undefined,
          accessDetails: estimate?.properties?.accessDetails,
          companyContact: estimate?.properties?.contacts?.find(
            (c) =>
              c.approvalType === APPROVAL_TYPE.PAY_AT_CLOSE &&
              c.isPayer &&
              c.role === CONTACT_ROLE.OTHER
          ),
          propertyListed:
            typeof estimate?.properties?.propertyListed !== 'undefined'
              ? estimate?.properties?.propertyListed
              : undefined,

          paymentOption:
            estimate?.properties?.payAtClose !== undefined &&
            estimate?.properties?.payAtClose !== null &&
            estimate?.properties?.payAtClose
              ? 'pay_at_close'
              : 'pay_at_completion',

          scheduler: estimate?.properties?.contacts?.find((c) => c.isScheduler),
          schedulerSelect: contactScheduler
            ? contactScheduler?.email === userEmail
              ? 'me'
              : isHomeowner
              ? 'other'
              : 'homeowner'
            : 'me',
          contractType: estimate?.properties?.contractType,
          propertyToBeSold: estimate?.properties?.propertyToBeSold,
          lockbox: estimate?.properties?.lockbox,
          lockboxConsent: estimate?.properties?.lockboxConsent,
          smsConsent: estimate?.properties?.smsConsent,
          approximateHomeEquity: estimate?.properties?.approximateHomeEquity,
          homeownershipConfirmation: estimate?.properties?.homeownershipConfirmation,
          agentAsHomeownerRepresentative:
            contactInEstimate?.role === CONTACT_ROLE.AGENT_HOMEOWNER
              ? 'myself'
              : null,
        },
      })
    )
  }, [estimate])

  useEffect(() => {
    fetchEstimate()
  }, [fetchEstimate])

  useEffect(() => {
    dispatch(
      approvalActions.setApprovalValue({
        attr: 'isOverInstallmentThreshold',
        value: isOverInstallmentThreshold,
      })
    )
    dispatch(
      approvalActions.setApprovalValue({
        attr: 'homeowner',
        value: estimateHomeowner,
      })
    )
    if (estimateHomeowners.length > 1) {
      dispatch(
        approvalActions.setApprovalValue({
          attr: 'secondHomeowner',
          value: estimateHomeowners[1],
        })
      )
    }
  }, [dispatch, isOverInstallmentThreshold, estimate])

  useEffect(() => {
    dispatch(
      approvalActions.setApprovalValue({
        attr: 'agentIsHomeowner',
        value: agentIsHomeowner,
      })
    )
  }, [estimate, role, agentIsHomeowner])

  if (contactInEstimate?.approvalType === APPROVAL_TYPE.APPROVED) {
    pushForward('success')
  }

  return (
    <DesktopPage
      title={`PROJECT FOR ${estimate?.properties?.address?.line_1 || ''}`}
      showBanner={false}
      titleAlign="left"
    >
      <PrivateLoader loading={pageLoading}>
        {isHomeowner || agentIsHomeowner ? (
          //HOMEOWNER FLOW
          secondApproval ? (
            <HomeownerSecondApprovalStepper />
          ) : (
            <HomeownerStepper />
          )
        ) : (
          //AGENT FLOW
          <AgentStepper />
        )}
      </PrivateLoader>
    </DesktopPage>
  )
}

export default ApproveViewComponent
